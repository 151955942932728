import React, {Component} from "react";
import {ThemeProvider} from "@mui/material/styles";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import theme from "./theme";
import storeRedux from "./redux/store";
import Router from "./router";
import {YMaps} from "@pbe/react-yandex-maps";

class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Provider store={storeRedux}>
                    <BrowserRouter>
                        <YMaps
                            query={{
                                load: "package.full",
                                lang: 'ru_RU',
                                apikey: '09598fee-4c77-46ed-a450-e24e440ff76b',
                            }}
                        >
                            <Router/>
                        </YMaps>
                    </BrowserRouter>

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnHover
                    />
                </Provider>
            </ThemeProvider>
        )
    }
}

export default App
