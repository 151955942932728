import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {setUser} from '../states/global';
import {getStores} from "../states/stores";
import {
    Notification,
    NotificationTypes
} from "../common/Notification"

import MainRoute from "./Main";
import NotAuth from "./NotAuth";
import agent from "../agent/agent";
import urls from "../variables/urls";

class Router extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount = async () => {
        await this.getUser();
    }

    getUser = async () => {
        const jwt = localStorage.getItem('jwt');

        if (!jwt) {
            this.setState({ isLoading: false });

            return null
        }

        const data = await agent.get(urls.usersMe).then((res) => {
            return res.data
        }).catch((err) => {
            return null
        });

        if (!data) {
            localStorage.removeItem('jwt');
            this.setState({ isLoading: false });

            return null
        }

        if (data?.user?.role !== "driver") {
            localStorage.removeItem("jwt");
            agent.defaults.headers['x-auth-token'] = "";

            Notification({
                type: NotificationTypes.error,
                message: "У вас нет доступа до данного сервиса, обратитесь к администратору."
            })

            await this.setState({isLoading: false});

            return
        }

        this.props.setUser(data.user);
        this.setState({ isLoading: false });

        this.props.getStores();
    }

    render() {
        const { isLoading } = this.state;
        const { user } = this.props.global;

        if (isLoading) {
            return null
        }

        if (!user) {
            return (
                <NotAuth/>
            )
        }

        return (
            <>
                <MainRoute/>
            </>
        )
    }
}

export default compose(
    connect(
        state => ({
            global: state.global
        }),
        dispatch => ({
            setUser: (user) => dispatch(setUser(user)),

            getStores: () => dispatch(getStores())
        }),
    ),
)(Router);
