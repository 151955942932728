import React from 'react';
import {
    Autocomplete,
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    FormControl,
    InputLabel,
    MenuItem,
    Select, TextField,
    Typography
} from "@mui/material";
import {
    Formik
} from "formik";
import banks from "../../../../../../constants/banks";

const DialogAddBank = (props) => {
    const {
        isOpen,
        onClose,
        onAdd,
    } = props;

    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        bank: "",
        bankNameForWithdrawal: "",
    });

    React.useEffect(() => {
        if (isOpen) {
            setInitialValues({
                bankNameForWithdrawal: "",
            });
        }
    }, [isOpen])

    const onSubmit = (form) => {
        const newForm = {
            bankNameForWithdrawal: form.bankNameForWithdrawal,
        };

        onAdd(newForm);
        handleCloseModal();
    };

    const handleChangeBankAutocomplete = (value) => {
        const newForm = refFormik.current.values;

        if (value !== null) {
            const {name, id} = value;
            newForm.bank = value;
            newForm.bankNameForWithdrawal = name;
        } else {
            newForm.bank = "";
            newForm.bankNameForWithdrawal = "";
        }

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">Добавление банка</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Autocomplete
                                        fullWidth
                                        options={banks}
                                        value={values.bank}
                                        name="bank"
                                        noOptionsText='Нет такого банка. Обратитесь к администратору'
                                        getOptionLabel={(option) => option.name || option}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Банк"
                                                placeholder='Введите название банка'
                                                error={Boolean(touched.bankNameForWithdrawal && errors.bankNameForWithdrawal)}
                                                helperText={touched.bankNameForWithdrawal && errors.bankNameForWithdrawal}
                                            />
                                        }

                                        onChange={(e, value) => handleChangeBankAutocomplete(value)}
                                    />
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={handleCloseModal}
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}
                                    >
                                        Отменить
                                    </Button>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}

                                        onClick={handleSubmit}
                                    >
                                        Добавить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default DialogAddBank;