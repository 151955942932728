import React from 'react';
import {useSelector} from "react-redux";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import banks from "../../../../../../constants/banks";

const DialogEditBank = (props) => {
    const {
        isOpen,
        onClose,
        onEdit,
    } = props;

    const user = useSelector(state => state.global.user);
    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        bank: "",
        bankNameForWithdrawal: "",
    });

    React.useEffect(() => {
        if (isOpen) {
            if (user.bankNameForWithdrawal && user.bankNameForWithdrawal !== null) {
                setInitialValues({
                    bank: {
                        name: user.bankNameForWithdrawal,
                    },
                    bankNameForWithdrawal: user.bankNameForWithdrawal,
                });
            } else {
                setInitialValues({
                    bank: "",
                    bankNameForWithdrawal: "",
                });
            }
        }
    }, [isOpen]);

    const onSubmit = (form) => {
        const newForm = {
            bankNameForWithdrawal: form.bankNameForWithdrawal,
        };

        onEdit(newForm);
        handleCloseModal();
    };

    const handleChangeBankAutocomplete = (value) => {
        const newForm = refFormik.current.values;

        if (value !== null) {
            const {name, id} = value;
            newForm.bank = value;
            newForm.bankNameForWithdrawal = name;
        } else {
            newForm.bank = "";
            newForm.bankNameForWithdrawal = "";
        }

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">Редактирование банка</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Autocomplete
                                        fullWidth
                                        options={banks}
                                        value={values.bank}
                                        name="bank"
                                        noOptionsText='Нет такого банка. Обратитесь к администратору'
                                        getOptionLabel={(option) => option.name || option}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Банк"
                                                placeholder='Введите название банка'
                                            />
                                        }

                                        onChange={(e, value) => handleChangeBankAutocomplete(value)}
                                    />
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={handleCloseModal}
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}
                                    >
                                        Отменить
                                    </Button>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}

                                        onClick={handleSubmit}
                                    >
                                        Редактировать
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default DialogEditBank;