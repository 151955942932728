import React, {Component} from "react";
import {Backdrop, Box, CircularProgress, Divider, Grid, Typography} from "@mui/material";
import {
    DialogAcceptOrder as DialogAcceptOrderComponent,
    DialogFormDeliveredToClient,
    DialogFullReturnFill as DialogFullReturnFillComponent,
    DialogPartialReturnFill as DialogPartialReturnFillComponent,
    DialogReturnConfirm as DialogReturnConfirmComponent,
    DialogTransferReturnToProvider as DialogTransferReturnToProviderComponent,
    DialogTransferTheGoodsConfirm as DialogTransferTheGoodsConfirmComponent,
    InformationAddresses,
    InformationProducts,
    LifeCycle as LifeCycleComponent,
    OrderPhotos as OrderPhotosComponent,
    ReadyOrderInformation as ReadyOrderInformationComponent,
    DialogAddBillOfLadingPhotos as DialogAddBillOfLadingPhotosComponent,
} from "./components";
import agent from "../../../../agent/agent";
import {withStyles} from "@mui/styles";
import {Notification, NotificationTypes} from "../../../../common/Notification";
import {DialogConfirmation} from "../../../../components";

class MyOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: {},
            returnItems: [],
            shortageItems: [],

            orderId: props?.match?.params?.id,

            providerPhotos: [],
            driverPhotos: [],
            returnPhotos: [],
            billOfLadingPhotos: [],

            settings: {},

            isOpenTransferTheGoodsConfirm: false,
            isOpenDeliveredToClient: false,
            isOpenPartialReturnFill: false,
            isOpenFullReturnFill: false,
            isOpenTransferReturnToProvider: false,
            isOpenAcceptOrder: false,
            isOpenReturnConfirm: false,
            isOpenAddBillOfLadingPhotos: false,

            isLoading: true,
            isLoadingProviderPhotos: false,
            isLoadingDriverPhotos: false,
            isLoadingReturnPhotos: false,
            isLoadingBillOfLadingPhotos: false,
            isLoadingSendLinkToAccount: false,

            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.getSettings();
        await this.getReturnItems();
        await this.getShortageItems();
        await this.getOrder();
    }

    // Получение заказа
    getOrder = async () => {

        const orderId = this.state.orderId;

        const order = await agent.get(`/orders/${orderId}`).then((res) => {
            return res.data?.order || {}
        }).catch(() => {
            return {}
        });

        this.setState({
            order,
            isLoading: false,
        })
    }

    getSettings = async () => {
        const settings = await agent.get(`/settings`)
            .then((res) => res.data.settings)
            .catch((err) => {
            });

        this.setState({settings});
    }

    // Получение возвращаемых товаров
    getReturnItems = async () => {
        const response = await agent.get(`/returns/${this.state.orderId}/items`)
            .then(res => res.data.returnItems)
            .catch(err => []);

        this.setState({
            returnItems: response,
        });
    };

    // Получение невозврат товаров
    getShortageItems = async () => {
        const response = await agent.get(`/returns/${this.state.orderId}/shortage-items`)
            .then(res => res.data.shortageItems)
            .catch(err => {
                return [];
            });

        this.setState({
            shortageItems: response,
        });
    };

    // Логика получения фотографий прикрепленных к заказу
    // TYPES - 'providerPhotos' | 'driverPhotos' | 'returnPhotos' | 'billOfLadingPhotos'
    getOrderPhotosUsingType = async (type = '', callback) => {
        this.setLoadingStatusOrderPhotos(type, true);

        const response = await agent.get(`/orders/${this.state.orderId}/photos?neededType=${type}`)
            .then(res => res.data.photos)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setLoadingStatusOrderPhotos(type, false);

            Notification({
                message: response.error?.data?.message || "Ошибка при загрузке фотографий",
                type: NotificationTypes.error,
            });

            return null;
        }

        let newPhotos = [];

        if (response.length > 0) {
            if (Array.isArray(response) && response.length > 0) {
                newPhotos = [...response.map(receipt => window.URL.createObjectURL(new Blob([new Uint8Array(receipt.photo.data).buffer])))];
            }
        }

        this.setState({[type]: newPhotos});

        this.setLoadingStatusOrderPhotos(type, false);

        if (newPhotos.length > 0) {
            callback();
        } else {
            Notification({
                message: 'Не удалось найти фотографии, попробуйте позже или обратитесь к администратору',
                type: NotificationTypes.error,
            });
        }
    };

    // Логика прикрепления фотографий накладных
    attachBillOfLadingPhotos = async (photos, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотите прикрепить фотографии?",
                acceptButtonTitle: "Да, прикрепить",
                acceptButtonAction: this.attachBillOfLadingPhotos.bind(this, photos, true),
            });

            return
        }

        this.setState({isShowBackdrop: true});

        const body = {
            orderId: this.state.orderId,
            billOfLadingPhotoInBase64: photos,
        };

        const response = await agent.post(`/orders/${this.state.orderId}/attach-bill-of-lading`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при добавлении фотографий",
                type: NotificationTypes.error,
            });

            return;
        }

        let newPhotos = [];

        if (Array.isArray(response.photos) && response.photos.length > 0 && this.state.billOfLadingPhotos.length > 0) {
            newPhotos = [...response.photos.map(photo => window.URL.createObjectURL(new Blob([new Uint8Array(photo.data).buffer])))];
        }

        await this.getOrder();

        this.setState({
            isShowBackdrop: false,
            billOfLadingPhotos: [...this.state.billOfLadingPhotos, ...newPhotos],
        });

        Notification({
            message: "Фотографии успешно прикреплены",
            type: NotificationTypes.success,
        });

    };

    // Переключение лоадеров при подгрузке фотографий прикрепленных к заказу
    setLoadingStatusOrderPhotos = (type = '', status = false) => {
        type === 'providerPhotos' && this.setState({isLoadingProviderPhotos: status});
        type === 'driverPhotos' && this.setState({isLoadingDriverPhotos: status});
        type === 'returnPhotos' && this.setState({isLoadingReturnPhotos: status});
        type === 'billOfLadingPhotos' && this.setState({isLoadingBillOfLadingPhotos: status});
    };

    // Логика работы по статусам
    setOrderStatus = async (status, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите изменить статус готовности заказа?`,
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.setOrderStatus.bind(this, status, true)
            });

            return
        }

        this.setState({isShowBackdrop: true});

        if (status === "partialReturn" || status === 'fullReturn') {
            const response = await agent.put(`/returns/${this.state.order.id}`, {
                orderId: this.state.order.id,
                returnType: status,
            }).then((res) => {
                return res.data
            }).catch((err) => {
                return {error: err.response}
            });
            if (response.error) {
                this.setState({isShowBackdrop: false});
                Notification({
                    type: NotificationTypes.error,
                    message: response.error?.data?.message || "Ошибка сервера"
                })
                return
            }
            await this.getOrder();
            this.setState({isShowBackdrop: false});
            Notification({
                type: NotificationTypes.success,
                message: "Статус заказа успешно изменен"
            });
        } else {
            const response = await agent.put(`/orders/${this.state.order.id}/set-status`, {
                orderId: this.state.order.id,
                deliveryStatus: status,
            }).then((res) => {
                return res.data
            }).catch((err) => {
                return {error: err.response}
            });
            if (response.error) {
                this.setState({isShowBackdrop: false});

                Notification({
                    type: NotificationTypes.error,
                    message: response.error?.data?.message || "Ошибка сервера"
                })

                return
            }

            await this.getOrder();

            this.setState({isShowBackdrop: false});

            Notification({
                type: NotificationTypes.success,
                message: "Статус заказа успешно изменен",
            });
        }
    }

    // Логика подтверждения принятия груза
    transferTheGoodsConfirm = async (code, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить принятие груза?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.transferTheGoodsConfirm.bind(this, code, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${this.state.orderId}/confirm`, {
            code,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            })

        if (response.error) {
            this.setState({isShowBackdrop: false});
            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении принятии заказа",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Груз успешно принят",
            type: NotificationTypes.success,
        });
    };

    // Логика доставки заказа до заказчика
    deliveredToClient = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить доставку заказа?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.deliveredToClient.bind(this, form, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${this.state.orderId}/transfer`, {
            orderPhotosInBase64: form.photo,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            })

        if (response.error) {
            this.setState({isShowBackdrop: false});
            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении доставки заказа",
                type: NotificationTypes.error,
            })

            return null;
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Заказ успешно доставлен",
            type: NotificationTypes.success,
        });
    };

    //Частичный возврат (return-fill)
    partialReturnFill = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите провести частичный возврат?`,
                acceptButtonTitle: "Да, провести",
                acceptButtonAction: this.partialReturnFill.bind(this, form, true)
            });

            return null;
        }

        this.setState({isShowBackdrop: true})

        const response = await agent.put(`/returns/${this.state.orderId}/return-fill`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            });

            return null
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            type: NotificationTypes.success,
            message: "Частичный возврат успешно проведен, ожидайте подтверждения от заказчика",
        });
    };
    //Частичный возврат (return-fill)
    fullReturnFill = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите провести полный возврат?`,
                acceptButtonTitle: "Да, провести",
                acceptButtonAction: this.fullReturnFill.bind(this, form, true)
            });

            return
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/returns/${this.state.orderId}/return-fill`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            });

            return null
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            type: NotificationTypes.success,
            message: "Полный возврат успешно проведен, ожидайте подтверждения от заказчика",
        });
    };
    // Трансфер возврата до провайдера
    transferReturnToProvider = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить доставку возврата до провайдера?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.transferReturnToProvider.bind(this, form, true)
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/returns/${this.state.orderId}/transfer-return-to-provider`, form)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при доставке возврата до исполнителя",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Доставка до исполнителя успешно подтверждено, дождитесь подтвеждения от исполнителя",
            type: NotificationTypes.success,
        });
    };

    // Подтверждение возврата
    confirmReturn = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить возврат?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.confirmReturn.bind(this, form, true)
            });

            return null;
        }

        this.setState({isShowBackdrop: true})

        const response = await agent.put(`returns/${this.state.orderId}/return-confirmation`, {
            code: form.code,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении возврата",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Возврат успешно подтвержден",
            type: NotificationTypes.success,
        });
    };

    // Передача груза заказчику
    acceptOrder = async (code, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите передать груз заказчику?`,
                acceptButtonTitle: "Да, передать",
                acceptButtonAction: this.acceptOrder.bind(this, code, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/orders/${this.state.orderId}/confirm`, {code})
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при передаче груза заказчику",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Груз успешно передан заказчику. Заказ считается выполненным",
            type: NotificationTypes.success,
        });
    };

    // Логика отправки ссылки на личный кабинет пользователя
    sendLinkToAccount = async () => {
        this.setState({isLoadingSendLinkToAccount: true});

        const response = await agent.post('/messages', {
            userId: this.state.order.purchaserUserId,
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isLoadingSendLinkToAccount: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при отправке ссылки на личный кабинет",
                type: NotificationTypes.error,
            });

            return;
        }

        this.setState({isLoadingSendLinkToAccount: false});

        Notification({
            message: "Ссылка на личный кабинет успешно отправлена",
            type: NotificationTypes.success,
        });
    };

    // Получение статусов по заказу
    _statusItems = () => {

        if (this.state.order.deliveryStatus === "canceled") {
            return [
                {
                    label: "Отменен",
                    active: true,
                    error: true,
                }
            ]
        }

        return [
            {
                label: "Принят в работу",
                active: true
            },
            {
                label: "Загружен",
                active: [
                    "enRoute",
                    "transferringToClient",
                    "received",
                    "partialReturn",
                    "fullReturn",
                ].includes(this.state.order.deliveryStatus)
            },
            {
                label: "Доставлен",
                active: [
                    "transferringToClient",
                    "received",
                    "partialReturn",
                    "fullReturn",
                ].includes(this.state.order.deliveryStatus)
            },
            {
                label: (['fullReturn'].includes(this.state.order.deliveryStatus) && this.state.order.statusOfReturnOrder === 'received' ? "Не выполнен" : "Выполнен"),
                active: ["received"].includes(this.state.order.deliveryStatus) || (['partialReturn'].includes(this.state.order.deliveryStatus) && this.state.order.statusOfReturnOrder === 'received'),
                error: (['fullReturn'].includes(this.state.order.deliveryStatus) && this.state.order.statusOfReturnOrder === 'received'),
            },
        ]
    }
    // Получение кнопок управление "Готовность"
    _buttonReady = () => {
        let buttons = [];

        const {order} = this.state;
        const deliveryStatus = this.state.order.deliveryStatus;

        if (deliveryStatus === 'transferringToDelivery') {
            buttons.push({
                label: "Принять груз",
                onClick: this.setIsOpenTransferTheGoodsConfirm.bind(this, true),
            })
        }

        if (deliveryStatus === 'enRoute') {
            buttons.push({label: "Заказ доставлен", onClick: this.setIsOpenDeliveredToClient.bind(this, true)});
        }

        // Завершение заказа (передача заказа заказчику)
        if ([
            "transferringToClient",
        ].includes(deliveryStatus) || (["partialReturn", "fullReturn"].includes(deliveryStatus) && (order.discount !== 0 || order.adminDiscount !== 0) && order.statusOfReturnOrder === 'created')) {
            buttons.push({label: "Передать груз", onClick: this.setIsOpenAcceptOrder.bind(this, true)});
        }

        // Частичный возврат (установка статуса, активировать кнопку "Скидка" у исполнителя)
        if ([
            "transferringToClient",
        ].includes(deliveryStatus)) {
            buttons.push({label: "Частичный возврат", onClick: this.setOrderStatus.bind(this, 'partialReturn', false)});
        }

        // Полный возврат (установка статуса, активировать кнопку "Скидка" у исполнителя)
        if ([
            "transferringToClient",
        ].includes(deliveryStatus)) {
            buttons.push({label: "Полный возврат", onClick: this.setOrderStatus.bind(this, 'fullReturn', false)});
        }

        //Частичный возврат (возврат товаров)
        if (deliveryStatus === 'partialReturn' && order.statusOfReturnOrder === 'created') {
            buttons.push({label: "Возврат", onClick: this.setIsOpenPartialReturnFill.bind(this, true)});
        }

        // Полный возврат (возврат товаров)
        if (deliveryStatus === 'fullReturn' && order.statusOfReturnOrder === 'created') {
            buttons.push({label: "Возврат", onClick: this.setIsOpenFullReturnFill.bind(this, true)});
        }

        if (['partialReturn', 'fullReturn'].includes(deliveryStatus) && ['courierFillConfirmed'].includes(order.statusOfReturnOrder)) {
            buttons.push({
                label: "Возврат доставлен исполнителю",
                onClick: this.setIsOpenTransferReturnToProvider.bind(this, true),
            });
        }

        if (['partialReturn', 'fullReturn'].includes(deliveryStatus) && ['purchaserConfirmed', 'providerConfirmed'].includes(order.statusOfReturnOrder)) {
            buttons.push({label: "Подтвердить возврат", onClick: this.setIsOpenReturnConfirm.bind(this, true)});
        }

        return buttons;
    };
    _isVisibleActionsReady = () => {
        return [
            "enRoute",
            'transferringToDelivery',
            'transferringToClient',
        ].includes(this.state.order.deliveryStatus) || (['partialReturn', 'fullReturn'].includes(this.state.order.deliveryStatus) && ['created', 'purchaserConfirmed', 'courierFillConfirmed', 'providerConfirmed'].includes(this.state.order.statusOfReturnOrder));
    };

    // Открытие формы подтверждения принятия груза
    setIsOpenTransferTheGoodsConfirm = (isOpen) => {
        this.setState({
            isOpenTransferTheGoodsConfirm: isOpen,
        })
    };
    // Закрытие формы подтверждения принятия заказа
    handleCloseTransferTheGoodsConfirm = () => {
        this.setIsOpenTransferTheGoodsConfirm(false);

    };

    // Открытие формы доставки до заказчика
    setIsOpenDeliveredToClient = (isOpen) => {
        this.setState({
            isOpenDeliveredToClient: isOpen,
        })
    };
    // Закрытие формы доставки до заказчика
    handleCloseDeliveredToClient = () => {
        this.setIsOpenDeliveredToClient(false);
    };

    // Открытие формы заполнения частичного возврата
    setIsOpenPartialReturnFill = (isOpen) => {
        this.setState({isOpenPartialReturnFill: isOpen});
    };
    // закрытие формы заполнения частичного возврата
    handleClosePartialReturnFill = () => {
        this.setIsOpenPartialReturnFill(false);
    }

    // Открытие формы заполнения полного возврата
    setIsOpenFullReturnFill = (isOpen) => {
        this.setState({isOpenFullReturnFill: isOpen});
    };
    // Закрытие формы заполнения полного возврата
    handleCloseFullReturnFill = () => {
        this.setIsOpenFullReturnFill(false);
    };

    // Открытие формы подтверждения возврата
    setIsOpenReturnConfirm = (isOpen) => {
        this.setState({
            isOpenReturnConfirm: isOpen,
        });
    };
    // Закрытие формы подтверждения возврата
    handleCloseReturnConfirm = () => {
        this.setIsOpenReturnConfirm(false);
    };

    // Открытие формы трансфера возврата до провайдера
    setIsOpenTransferReturnToProvider = (isOpen) => {
        this.setState({isOpenTransferReturnToProvider: isOpen})
    };
    // Закрытие формы трансфера возврата до провайдера
    handleCloseTransferReturnToProvider = () => {
        this.setIsOpenTransferReturnToProvider(false);
    };

    // Открытие формы завершение заказа (передачи заказа заказчику)
    setIsOpenAcceptOrder = (isOpen) => {
        this.setState({
            isOpenAcceptOrder: isOpen,
        });
    };

    // Закрытие формы завершения заказа (передачи заказа заказчику)
    handleCloseAcceptOrder = () => {
        this.setIsOpenAcceptOrder(false);
    };

    //Открытие формы добавления фотографии накладных
    setIsOpenAddBillOfLadingPhotos = (isOpen) => {
        this.setState({isOpenAddBillOfLadingPhotos: isOpen});
    };
    // Закрытие формы добавления фотографии накладных
    handleCloseAddBillOfLadingPhotos = () => {
        this.setIsOpenAddBillOfLadingPhotos(false);
    };

    render() {
        const {
            order,
            returnItems,
            shortageItems,
            orderId,

            providerPhotos,
            driverPhotos,
            returnPhotos,
            billOfLadingPhotos,

            settings,

            isOpenTransferTheGoodsConfirm,
            isOpenDeliveredToClient,
            isOpenPartialReturnFill,
            isOpenFullReturnFill,
            isOpenTransferReturnToProvider,
            isOpenAcceptOrder,
            isOpenReturnConfirm,
            isOpenAddBillOfLadingPhotos,

            isLoading,
            isLoadingProviderPhotos,
            isLoadingDriverPhotos,
            isLoadingReturnPhotos,
            isLoadingBillOfLadingPhotos,
            isLoadingSendLinkToAccount,

            isShowBackdrop,
        } = this.state;
        const {
            classes
        } = this.props;

        if (Object.keys(order).length <= 0) {
            return (
                <Typography variant="h3">Загружаем заказ</Typography>
            )
        }

        return (
            <>
                <Box mb={ 2 }>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container alignItems="center" spacing={ 2 }>
                                <Grid item>
                                    <Typography variant="h1">
                                        Заказ №{ orderId }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <LifeCycleComponent
                                        items={ this._statusItems() }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box className={ classes.content }>

                    { !isLoading && (
                        <InformationAddresses
                            order={ order }
                            settings={ settings }

                            isLoadingSendLinkToAccount={ isLoadingSendLinkToAccount }

                            setIsOpenAddBillOfLadingPhotos={ this.setIsOpenAddBillOfLadingPhotos }
                            onSendLinkToAccount={ this.sendLinkToAccount }
                        />
                    ) }

                    <Box mt={ 4 }/>
                    <Divider sx={ { margin: "0 -24px" } }/>
                    <Box mt={ 4 }/>

                    <InformationProducts
                        returnItems={ returnItems }
                        shortageItems={ shortageItems }
                        products={ order?.orderItems || [] }

                        deliveryStatus={ order.deliveryStatus }
                    />

                    <Box mt={ 4 }/>
                    <Divider sx={ { margin: "0 -24px" } }/>
                    <Box mt={ 4 }/>

                    { (order.attachedPhotosFlags &&
                        (
                            order.attachedPhotosFlags.billOfLadingPhotos ||
                            order.attachedPhotosFlags.fromDriver ||
                            order.attachedPhotosFlags.fromProvider
                        )
                    ) && (
                        <OrderPhotosComponent
                            order={ order }
                            orderDeliveryStatus={ this.state.order?.deliveryStatus || "" }
                            attachedPhotoFlags={ order.attachedPhotosFlags }

                            providerPhotos={ providerPhotos }
                            driverPhotos={ driverPhotos }
                            returnPhotos={ returnPhotos }
                            billOfLadingPhotos={ billOfLadingPhotos }

                            isLoadingProviderPhotos={ isLoadingProviderPhotos }
                            isLoadingDriverPhotos={ isLoadingDriverPhotos }
                            isLoadingReturnPhotos={ isLoadingReturnPhotos }
                            isLoadingBillOfLadingPhotos={ isLoadingBillOfLadingPhotos }

                            getPhotos={ this.getOrderPhotosUsingType }
                            setIsOpenAddBillOfLadingPhotos={ this.setIsOpenAddBillOfLadingPhotos }
                        />
                    ) }

                    <Box mt={ 4 }/>
                    <Divider sx={ { margin: "0 -24px" } }/>
                    <Box mt={ 4 }/>

                    { Boolean(this._isVisibleActionsReady()) && (
                        <ReadyOrderInformationComponent
                            actions={ this._buttonReady() }
                        />
                    ) }

                </Box>

                <DialogTransferTheGoodsConfirmComponent
                    isOpen={ isOpenTransferTheGoodsConfirm }
                    onClose={ this.handleCloseTransferTheGoodsConfirm }
                    onConfirm={ this.transferTheGoodsConfirm }
                />

                <DialogFormDeliveredToClient
                    order={ order }
                    isOpen={ isOpenDeliveredToClient }
                    onClose={ this.handleCloseDeliveredToClient }
                    onDelivered={ this.deliveredToClient }
                />

                <DialogAcceptOrderComponent
                    isOpen={ isOpenAcceptOrder }
                    onClose={ this.handleCloseAcceptOrder }
                    onAccept={ this.acceptOrder }
                />

                <DialogAddBillOfLadingPhotosComponent
                    isOpen={ isOpenAddBillOfLadingPhotos }

                    onAttach={ this.attachBillOfLadingPhotos }
                    onClose={ this.handleCloseAddBillOfLadingPhotos }
                />

                { [ 'fullReturn', 'partialReturn' ].includes(order.deliveryStatus) && (
                    <>
                        <DialogPartialReturnFillComponent
                            order={ order }
                            isOpen={ isOpenPartialReturnFill }
                            onClose={ this.handleClosePartialReturnFill }
                            onReturnFill={ this.partialReturnFill }
                        />

                        <DialogFullReturnFillComponent
                            order={ order }
                            isOpen={ isOpenFullReturnFill }
                            onClose={ this.handleCloseFullReturnFill }
                            onReturnFill={ this.fullReturnFill }
                        />

                        <DialogReturnConfirmComponent
                            isOpen={ isOpenReturnConfirm }
                            onClose={ this.handleCloseReturnConfirm }
                            onConfirm={ this.confirmReturn }
                        />

                        { [ 'courierFillConfirmed' ].includes(order.statusOfReturnOrder) && (
                            <DialogTransferReturnToProviderComponent
                                order={ order }
                                returnItems={ returnItems }
                                isOpen={ isOpenTransferReturnToProvider }
                                onClose={ this.handleCloseTransferReturnToProvider }
                                onTransfer={ this.transferReturnToProvider }
                            />
                        ) }
                    </>
                ) }

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }
}

const styles = {
    content: {
        padding: 24,
        borderRadius: 10,
        backgroundColor: "#FBFBFB"
    }
};

export default withStyles(styles)(MyOrder)
