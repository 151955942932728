import React from 'react';
import {
    Box,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Pagination,
    Skeleton
} from "@mui/lab";
import moment from "moment";
import {
    convertorNumber
} from "../../../../../../helper/convertor";
import transactionType from "../../../../../../constants/transactionType";
import clsx from "clsx";

const TableComponent = (props) => {
    const {
        wallet,

        transactions,
        filter,
        pagination,
        isLoading,

        onChangeFilter,
        onChangePagination,
    } = props;

    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    const handleChangeFilter = (name, value) => {
        const newFilter = {...filter};
        newFilter[name] = value;

        onChangeFilter(newFilter, true);
    };

    const isAddition = (transaction) => {
        return wallet.id === transaction.walletTo.id;
    };

    return (
        <>
            <Box className={classes.titleWrapper}>
                <Typography variant="h2">История операций</Typography>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell align="left">Отправитель</TableCell>
                        <TableCell align="left">Получатель</TableCell>
                        <TableCell>Тип операции</TableCell>
                        <TableCell width="350px">Комментарий</TableCell>
                        <TableCell align="right">Сумма</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Boolean(isLoading)
                        ? (
                            <>
                                {
                                    [1, 2, 3, 4, 5].map(item => (
                                        <TableRow key={item}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>
                                    ))
                                }
                            </>
                        )
                        : (
                            <>
                                {transactions.length > 0
                                    ?
                                    <>
                                        {transactions.map(({transaction, type}) => (
                                            <TableRow key={transaction.id}>
                                                <TableCell>{moment(transaction?.createdAt).format("DD.MM.YYYY HH:mm") || "-"}</TableCell>
                                                <TableCell align="left">
                                                    {Boolean(transaction.walletFrom?.users[0])
                                                        ? (
                                                            <Box>
                                                                <Box className={classes.rowContent}>
                                                                    <Typography>Имя:</Typography>
                                                                    <Typography className={classes.value}>
                                                                        {transaction.walletFrom?.users[0]?.firstName || "-"} {transaction?.walletFrom?.users[0]?.lastName || "-"}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                        : "-"
                                                    }
                                                </TableCell>
                                                <TableCell align="left">
                                                    {Boolean(transaction.walletTo?.users[0])
                                                        ? (
                                                            <Box>
                                                                <Box className={classes.rowContent}>
                                                                    <Typography>Имя:</Typography>
                                                                    <Typography className={classes.value}>
                                                                        {transaction?.walletTo?.users[0]?.firstName || "-"} {transaction?.walletTo?.users[0]?.lastName || "-"}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                        : "-"
                                                    }
                                                </TableCell>
                                                <TableCell>{transactionType[type] || "-"}</TableCell>
                                                <TableCell dangerouslySetInnerHTML={{__html: transaction?.comment || "-"}}/>
                                                <TableCell
                                                    align="right"
                                                    className={clsx({
                                                        [classes.additionValue]: isAddition(transaction) && type !== 'withdrawal',
                                                        [classes.reduceValue]: Boolean(!isAddition(transaction)) || type === 'withdrawal',
                                                    })}
                                                >
                                                    {((isAddition(transaction) && type !== 'withdrawal') ? "+" : "-")} {convertorNumber(transaction?.amount || 0, 2, '.')} ₽
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                    :
                                    <>
                                        <TableRow>
                                            <TableCell align="center" colSpan={5}>Нет операций</TableCell>
                                        </TableRow>
                                    </>
                                }
                            </>
                        )
                    }
                </TableBody>
            </Table>

            {transactions.length > 0 && (
                <Pagination
                    page={Number(pagination.page)}
                    count={Number(pagination.totalPage)}

                    onChange={handleChangePage}
                />
            )}
        </>
    );
};

const useStyles = makeStyles({
    rowContent: {
        display: "flex",
    },

    value: {
        color: "#84b92c",
        marginLeft: 4,
    },

    titleWrapper: {
        marginBottom: 16,
        display: "flex",
        alignItems: "center",
    },
    periodButton: {
        "&.MuiButton-root": {
            marginLeft: 24,
            height: "auto",
            "&:last-child": {
                marginLeft: 8,
            }
        }
    },

    additionValue: {
        "&.MuiTableCell-root": {
            color: "#0f9c00",
        },
    },
    reduceValue: {
        "&.MuiTableCell-root": {
            color: "#ee0404",
        },
    },
})

export default TableComponent;