import React from "react";
import {Redirect, Switch} from "react-router-dom";

import {RouteWithLayout} from "../components";
import {Default as DefaultLayout} from "../layouts";
import {
    MyOrder as MyOrderPage,
    MyOrders as MyOrdersPage,
    Profile as ProfilePage,
    Wallet as WalletPage,
} from "../pages";

const pages = [
    {
        path: '/my-list',
        component: MyOrdersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/my-list/order/:id',
        component: MyOrderPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/wallet',
        component: WalletPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/profile',
        component: ProfilePage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
];

const MainRoutes = () => {
    return (
        <Switch>
            {
                pages.map((page, idx) => (
                    <RouteWithLayout
                        key={'page-' + idx}
                        {...page}
                    />
                ))
            }

            <Redirect to="/my-list"/>
        </Switch>
    );
};

export default MainRoutes;
