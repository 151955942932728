import React, {Component} from 'react';
import {
    Backdrop,
    Box,
    CircularProgress,
    Typography,
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";
import {
    DialogWithdrawalFunds as DialogWithdrawalFundsComponent,
    MainInformationComponent,
    TableComponent,
} from "./components";
import {
    DialogConfirmation
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import queryString from "query-string";

const initFilter = {
    'sort': "",
};

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wallet: {},
            transactions: [],
            balance: null,
            income: null,
            purchaseIncome: null,
            expense: null,

            regularRequest: {},
            withdrawalRequest: [],
            isRegularRequest: true,

            withdrawalType: "oneTime",

            filter: {...initFilter},
            pagination: {
                page: 1,
                totalPage: 1,
            },

            isOpenWithdrawalFunds: false,

            isLoading: true,
            isLoadingWithdrawalFunds: true,
            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getWithdrawalFunds();
        await this.getWallets();
        await this.getTransactions();
        await this.getWalletInformation();
        // await this.topUpWalletBalance();
    }

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initFilter,
            ...parseSearch
        };

        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    //Добавить пару рублей
    topUpWalletBalance = async () => {
        const response = await agent.put(`/finance/wallets/21/top-up`, {
            amount: 500000,
        })
            .then(res => res.data)
            .catch(err => err.response.data);
    };

    getWallets = async () => {
        const {user} = this.props;
        const {wallet} = await agent.get(`finance/wallets/${user.id}`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response.data.message}
            });
        this.setState({wallet})
    }

    getTransactions = async () => {
        const filter = this.getFilters();
        const {
            transactions,
            count
        } = await agent.get(`finance/transactions/wallets/${this.state.wallet.id}${filter}&limit=10`)
            .then(res => res.data)
            .catch(err => {
                return {
                    transactions: [],
                    count: 0,
                }
            })

        const pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(count / 10) || 1;

        this.setState({
            transactions,
            pagination,
        })

        this.setState({
            isLoading: false,
        })
    }

    getWalletInformation = async () => {
        const balance = await agent.get(`finance/wallets/${this.state.wallet.id}/balance`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response.data.message}
            })
        const income = await agent.get(`finance/wallets/${this.state.wallet.id}/active-role-income`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response.data.message}
            })
        const purchaseIncome = await agent.get(`finance/wallets/${this.state.wallet.id}/purchaser-income`)
            .then(res => res.data.expense)
            .catch(err => {
                return {error: err.response.data.message}
            })
        const expense = await agent.get(`finance/wallets/${this.state.wallet.id}/expense`)
            .then(res => res.data.expense)
            .catch(err => {
                return {error: err.response.data.message}
            });

        this.setState({
            balance,
            income: income.income,
            purchaseIncome: purchaseIncome,
            expense
        });
    }

    // Логика получения списка выплат
    getWithdrawalFunds = async () => {
        this.setState({
            isLoadingWithdrawalFunds: true,
        });
        const response = await agent.get(`request-for-withdrawal-funds`)
            .then(res => res.data.requests)
            .catch(err => []);

        let regularRequest = {};
        let withdrawalRequest = [];

        if (response.length > 0) {
            regularRequest = response.filter(item => item.withdrawalType === 'regular');
            withdrawalRequest = response.filter(item => item.withdrawalType !== 'regular');
        }

        if (regularRequest.length > 0) {
            regularRequest = regularRequest[regularRequest.length - 1];
        } else {
            regularRequest = {};
        }

        this.setState({
            withdrawalRequest,
            regularRequest: regularRequest,
            isRegularRequest: Object.keys(regularRequest).length > 0,
            isLoadingWithdrawalFunds: false,
        });
    };

    // Логика выплаты
    withdrawalFunds = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите получить оплату?`,
                cancelButtonTitle: "Отменить",
                acceptButtonTitle: "Да, получить",
                acceptButtonAction: this.withdrawalFunds.bind(this, form, true),
            });

            return
        }

        this.setState({isShowBackdrop: true});

        const body = {
            ...form,
            amount: +form.amount,
            withdrawalType: this.state.withdrawalType,
        };

        if (form.withdrawWholeAvailableSum) {
            delete body.amount;
        }

        const response = await agent.post(`/request-for-withdrawal-funds`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при получении оплаты",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getWithdrawalFunds();
        await this.getWallets();
        await this.getWalletInformation();
        await this.getTransactions();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Оплата успешно встала в очередь на выплату",
            type: NotificationTypes.success,
        });
    };

    // Логика отмены регулярной выплаты
    canceledRegularFunds = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите отменить регулярные выплаты?`,
                cancelButtonTitle: "Отменить",
                acceptButtonTitle: "Да, отменить",
                acceptButtonAction: this.canceledRegularFunds.bind(this, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const {
            regularRequest
        } = this.state;

        const response = await agent.delete(`/request-for-withdrawal-funds/${regularRequest.id}`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при отмене регулярной выплаты",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getWithdrawalFunds();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Регулярная выплата успешно отменена",
            type: NotificationTypes.success,
        });
    };

    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];

        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/wallet?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    changeFilter = async (filter, isFastStart) => {
        await this.setState({filter})
        if (!isFastStart) {
            return null;
        }

        await this.getTransactions();
    };

    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getTransactions();
    }

    // Открытие формы получения оплаты
    setIsOpenWithdrawalFunds = (isOpen, withdrawalType = this?.state?.withdrawalType || "oneTime") => {
        this.setState({
            isOpenWithdrawalFunds: isOpen,
            withdrawalType,
        });
    };
    // Закрытие формы получения оплаты
    handleCloseWithdrawalFunds = () => {
        this.setIsOpenWithdrawalFunds(false);
    };

    render() {
        const {
            classes
        } = this.props;
        const {
            wallet,
            transactions,
            balance,
            income,
            purchaseIncome,
            expense,

            regularRequest,
            withdrawalRequest,
            isRegularRequest,

            filter,
            pagination,

            isOpenWithdrawalFunds,

            isLoading,
            isLoadingWithdrawalFunds,
            isShowBackdrop
        } = this.state;
        return (
            <>
                <Box>
                    <Typography variant="h1" sx={{marginBottom: 2}}>Кошелек</Typography>
                </Box>
                <Box className={classes.contentWrapper}>
                    <Box mb={10}>
                        <MainInformationComponent
                            wallet={wallet}
                            isRegularRequest={isRegularRequest}
                            onOpenWithdrawalFunds={this.setIsOpenWithdrawalFunds}
                            onCanceledRegularFunds={this.canceledRegularFunds}
                        />
                    </Box>
                    <Box>
                        <TableComponent
                            wallet={wallet}
                            transactions={transactions}

                            filter={filter}
                            pagination={pagination}

                            isLoading={isLoading}

                            onChangeFilter={this.changeFilter}
                            onChangePagination={this.changePagination}
                        />
                    </Box>
                </Box>

                {!isLoadingWithdrawalFunds && (
                    <DialogWithdrawalFundsComponent
                        withdrawalRequest={withdrawalRequest}
                        isOpen={isOpenWithdrawalFunds}
                        onClose={this.handleCloseWithdrawalFunds}
                        onWithdrawal={this.withdrawalFunds}
                    />
                )}

                <DialogConfirmation
                    ref={this.refDialogConfirmation}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
};

const styles = {
    contentWrapper: {
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        padding: "16px 32px 24px",
    }
};

export default withStyles(styles)(Wallet);